<template>
  <div id="app">
    <BookMark/>
  </div>
</template>

<script>
import BookMark from './components/BookMark.vue'

export default {
  name: 'App',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BookMark
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  padding: 0;
  margin: 0;
}

body{
  padding: 0;
  margin: 0;
}
@media (prefers-color-scheme: dark) {
  .el-button.el-button--default{
    transition: unset;/*护眼模式背景色异常问题*/
  }
}
</style>
