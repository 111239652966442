<template>
  <div class="my_container">

    <!-- 头部 -->
    <div class="book_header">
      <div class="book_header_contain"
        shadow="never">
        <span class="mark_offset header_recover_font">
          恢复书签
        </span>
        <span :style="{'margin-left': '20px'}"
          class="header_recover_num_font">
          恢复指定时间的书签数
        </span>
      </div>
    </div>

    <!-- 如果是低版本的浏览器 -->
    <div class="lowversion_tip"
      v-if="isShowLowVersionTip">
      <div class="mark_offset">
        <div class="centerShow">
          <img :src="imgLogo"
            class="centerShowImg centerLogo" />
        </div>
        <div style="width: 962px;text-align: center;">
          <span class="centerShowImg">低版本不支持恢复书签，请先<a :href="downloadUrl"
              target="_blank">升级版本</a>噢~</span>
        </div>
      </div>
    </div>

    <!-- 删除提示 -->
    <div class="delete_tip"
      v-if="showBackDeleteTip">
      <span :style="{'vertical-align': 'middle'}">备份过多，已删除最早的备份~</span>
      <div class="delete_tip_close"
        @click="closeShowBackDeleteTip"></div>
    </div>

    <!-- 书签内容 -->
    <div class="mark_body"
      v-if="!isShowLowVersionTip">
      <!-- 当前书签信息 -->
      <div class="mark_now"
        v-if="!isShowNotSelfBrowserTip">
        <span class="mark_now_title">当前书签</span>
        <a class="mark_now_num">{{nowMarkNum}}条</a>
        <div @click="clickCurrentMark"
          class="mark_now_btn">
          <span>查看</span>
        </div>
        <span v-if="deviceName"
          class="mark_now_devicename">设备信息:{{deviceName}}</span>
      </div>

      <!-- 是本浏览器的情况下 -->
      <div v-if="!isShowNotSelfBrowserTip">
        <!-- 有登录态 -->
        <div v-show="hasLogin">
          <!-- 无书签显示 -->
          <div v-show="!hasBookMark"
            class="mark_offset">
            <div class="centerShow">
              <img src="../assets/no_mark.png"
                class="centerShowImg" />
            </div>
            <div style="width: 962px;text-align: center">
              <span>您的书签备份记录将会显示在这里</span>
            </div>
          </div>

          <div v-if="hasBookMark"
            class="mark_offset">
            <!-- 今日 -->
            <div class="mark_list"
              v-if="(todayBookMark&&todayBookMark.length>0)">
              <div class="mark_list_head">
                今天-{{todayFormat()}}
              </div>
              <div class="mark_list_item"
                v-for="(item,index) in todayBookMark"
                :key="index">
                <div class="item_time">
                  {{formatTimeToHms(item.add_time)}}
                </div>
                <div class="item_num">{{(item.pcnum+item.appnum)}}条</div>
                <div class="item_btns">
                  <button @click="recoverBookMarkByRow(item)"
                    :style="{'margin-left': '0px', 'width': '80px', 'height': '32px','background': '#FFFFFF'}"
                    class="common_btn">恢复</button>
                  <div @click="clickMarkViewItem(item, $event)"
                    class="history_book_view_btn">
                    <span>查看</span>
                  </div>
                  <div @click="deleteBookMarkByRow(item)"
                    class="history_book_clear_btn">
                    <span>删除</span>
                  </div>
                </div>
                <div class="item_device_name">设备信息:{{item.device_name}}</div>

              </div>
            </div>
            <!-- 历史 -->
            <div v-for="(historyItem, dayStr) in historyBookMark"
              :key="dayStr">
              <div class="mark_list"
                v-if="historyItem">
                <div class="mark_list_head">
                  {{dayStr}}
                </div>
                <div class="mark_list_item"
                  v-for="(item,index) in historyItem"
                  :key="index">
                  <div class="item_time">
                    {{formatTimeToHms(item.add_time)}}
                  </div>
                  <div class="item_num">{{(item.pcnum+item.appnum)}}条</div>
                  <div class="item_btns">
                    <button @click="recoverBookMarkByRow(item)"
                      :style="{'margin-left': '0px', 'width': '80px', 'height': '32px','background': '#FFFFFF'}"
                      class="common_btn">恢复</button>
                    <div @click="clickMarkViewItem(item, $event)"
                      class="history_book_view_btn">
                      <span>查看</span>
                    </div>
                    <div @click="deleteBookMarkByRow(item)"
                      class="history_book_clear_btn">
                      <span>删除</span>
                    </div>
                  </div>
                  <div class="item_device_name">设备信息:{{item.device_name}}</div>

                </div>
              </div>
            </div>
          </div>
          <!-- 有书签显示 -->
          <div v-show="hasBookMark"
            class="mark_offset">

          </div>
        </div>
        <!-- 无登录态的情况 -->
        <div v-show="!hasLogin">
          <div class="mark_offset">
            <div class="centerShow">
              <img src="../assets/login.png"
                class="centerShowImg" />
            </div>
            <div style="width: 962px;">
              <button class="centerShowImg common_btn"
                :style="{'width': '164px', 'height': '40px'}"
                @click="doLogin">登录立即备份书签</button>
            </div>
          </div>
        </div>
      </div>
      <!-- 不是本浏览器的情况下 -->
      <div v-if="isShowNotSelfBrowserTip">
        <div class="mark_offset">
          <div class="centerShow">
            <img :src="imgLogo"
              class="centerShowImg centerLogo" />
          </div>
          <div style="width: 962px;text-align: center;">
            <span class="centerShowImg">走错地方啦~请在<a :href="downloadUrl"
                target="_blank">{{chromeTitle}}</a>访问此网站~</span>
          </div>
        </div>
      </div>
    </div>

    <!--- 书签恢复弹窗 --->
    <el-dialog title="书签恢复"
      :visible.sync="clickRecoverMark"
      width="30%"
      center
      :lock-scroll="true"
      custom-class="my_pop_dialog">
      <div :style="{'text-align': 'center'}">确认将书签还原到该版本吗</div>
      <el-row slot="footer"
        class="dialog-footer">
        <el-col :span="4"
          class="ignore_tip_checkbox">
          <el-checkbox v-model="noTipRecover"
            class="ignore_tip_checkbox">不再提示</el-checkbox>
        </el-col>

        <el-col :span="12"
          :offset="12">
          <el-button type="primary"
            @click="doRecover(currentRecoverMarkItem)"
            size="small">确认</el-button>
          <el-button @click="cancelRecoverTip"
            size="small">取 消</el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <!--- 书签删除弹窗 --->
    <el-dialog title="书签删除"
      :visible.sync="clickClearMark"
      width="30%"
      :lock-scroll="true"
      custom-class="my_pop_dialog">
      <div :style="{'text-align': 'center'}">确认删除书签吗？</div>
      <el-row slot="footer">
        <el-col :span="4"
          class="ignore_tip_checkbox">
          <el-checkbox v-model="noTipDelete">不再提示</el-checkbox>
        </el-col>
        <el-col :span="12"
          :offset="12">
          <el-button type="primary"
            @click="doDelete(currentDeleteMarkItem)"
            size="small">确认</el-button>
          <el-button @click="cancelDeleteTip"
            size="small">取 消</el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <!-- 书签查看弹窗 -->
    <el-dialog title="查看书签内容"
      :visible.sync="bookMarkClick"
      width="30%"
      :style="{'padding-top': (autoDialogPosition.Top)+'px', 'padding-left': (autoDialogPosition.Left+80)+'px', 'width': '1200px', 'padding-bottom': '8px'}"
      custom-class="my_dialog">
      <div style="height:373px">
        <!-- 加载失败 -->
        <div v-show="loadMarkStatus==='fail'"
          :style="{'text-align': 'center'}">
          加载失败
        </div>
        <!-- 加载中 -->
        <div v-show="loadMarkStatus==='loading'"
          :style="{'text-align': 'center'}">
          加载中
        </div>
        <div v-if="loadMarkStatus!=='fail' && loadMarkStatus!=='loading'">
          <div class="need-padding">
            <!-- 搜索框 -->
            <el-input placeholder="搜索书签"
              v-model="currentFilterText"
              class="dialog_input">
            </el-input>
          </div>
          <!-- 节点树 -->
          <div class="link-line"></div>
          <div class="tree-box scroll-bar" style="height:313px">
            <el-tree v-if="bookMarkClick" 
              class="filter-tree"
              :data="curViewMarkDetail"
              :props="defaultProps"
              :filter-node-method="filterNode"
              node-key="id"
              ref="tree"
              :render-content="renderTreeContent"
              :default-expanded-keys="defaultExpandedKeys">
            </el-tree>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 当前书签的树形信息 -->
    <el-dialog title="查看书签内容"
      :visible.sync="showCurrentBook"
      width="30%"
      :style="{'padding-top': curMarkDialogPos.Top+'px', 'padding-left': curMarkDialogPos.Left+80+'px', 'width': '1200px'}"
      custom-class="my_dialog">
      <!-- 无书签内容 -->
      <!-- 有书签内容 -->
      <div>
        <div class="need-padding">
          <el-input placeholder="搜索书签"
            v-model="currentFilterText"
            class="dialog_input">
          </el-input>
        </div>
        <div class="link-line">
        </div>
        <div class="tree-box scroll-bar" style="height:313px">
          <el-tree v-if="showCurrentBook"
            class="filter-tree"
            :data="localMarkInfo"
            :props="defaultProps"
            :filter-node-method="filterNode"
            node-key="id"
            ref="tree"
            :render-content="renderTreeContent"
            :default-expanded-keys="defaultExpandedKeys">
          </el-tree>
        </div>
      </div>

    </el-dialog>
  </div>

</template>

<script>
import '../assets/css/icon.css'
import '../assets/css/page/book_mark.css'
import Common from '../common/common'
const defaultIcon = require("../assets/brower.png")
export default {
  watch: {
    currentFilterText(val) {
      this.$refs.tree && this.$refs.tree.filter(val)
      // val && this.$set(this.$refs.tree, 'defaultExpandAll', true)
    },
    serverLastAutoDeleteTime(val) {
      if (val > this.localAutoDeleteTime) {
        this.showBackDeleteTip = true
      }
    },
    bookMarkClick(val) {
      // 如果关闭的情况下，需要清空搜索框的内容
      if (!val) {
        this.currentFilterText = ""
      }
    },
    showCurrentBook(val) {
      if (!val) {
        this.currentFilterText = ""
      }
    }
  },
  computed:{
    defaultExpandedKeys(){
      if(this.bookMarkClick){
        if(this.curViewMarkDetail[0]){
          return [this.curViewMarkDetail[0].id]
        }
      }else if(this.showCurrentBook){
        if(this.localMarkInfo[0]){
          return [this.localMarkInfo[0].id]
        }
      }
      return []
    }
  },
  data () {
    let downloadUrl , chromeTitle ,imgLogo
    switch(window.location.host){
      case 'bmr.cqttech.com':
        downloadUrl = "https://dcbrowser.cqttech.com/"
        chromeTitle = '小智双核浏览器'
        imgLogo = require('../assets/logo.png')
        break
      case 'bmr-b.cqttech.com':
        downloadUrl = "https://b.cqttech.com"
        chromeTitle = '双核浏览器'
        imgLogo = require('../assets/logo_shuanghe.png')
        break
      case 'bmr-tsbrowser.xiangtatech.com':
        downloadUrl = "http://tsbrowser.xiangtatech.com/"
        chromeTitle = '极速浏览器'
        imgLogo = require('../assets/logo_jisu.png')
        break
      case 'bmr-xk.xkbrowser.com':
        downloadUrl = "http://www.xkbrowser.com/"
        chromeTitle = '小K浏览器'
        imgLogo = require('../assets/logo_xk.png')
        break
      default : break
    }
    return {
      nowMarkNum: 0,
      showCurrentBook: false,
      currentFilterText: '',
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      curViewMarkDetail: [],
      hasBookMark: false,
      todayBookMark: [],
      historyBookMark: {},
      hasLogin: false,
      todayCellIndex: -1,
      bookMarkClick: false,
      historyCellIndex: -1,
      clickRecoverMark: false,
      clickClearMark: false,
      noTipRecover: false,
      noTipDelete: false,
      currentRecoverMarkItem: {},
      currentDeleteMarkItem: {},
      showRecoverLoading: false,
      autoDialogPosition: {
        Top: 0,
        Left: 0
      },
      // 当前书签鼠标点击的位置
      curMarkDialogPos: {
        Top: 0,
        Left: 0
      },
      // 本地书签信息
      localMarkInfo: [],
      // 删除按钮
      showBackDeleteTip: false,
      // 加载书签的状态
      loadMarkStatus: 'loading',
      // 最近自动删除的时间
      serverLastAutoDeleteTime: '',
      // 本地上次显示删除的最后版本
      localAutoDeleteTime: '',
      // 是否显示不是本浏览器的功能
      isShowNotSelfBrowserTip: false,
      // 是否显示低版本的提示
      isShowLowVersionTip: false,
      downloadUrl,
      chromeTitle,
      imgLogo,
      // 当前设备信息名称
      deviceName: "",
      // 当前系统状态下是否有网络
      isOnLine:true
    }
  },
  methods: {
    // 检测登录态信息
    checkLoginStatus () {
      let that = this
      window.chrome.bookmarksRecover.getLoginStatus((status)=>{
        that.hasLogin = status == 1
        that.hasLogin && that.getBookMarkList()
      })
    },
    // 拉起登录对话框
    doLogin () {
      window.chrome.bookmarksRecover.reportId(16690007);//登录
      let that = this
      window.chrome.bookmarksRecover.login((status)=>{
        if (status === 1) {
          that.hasLogin = true
        }
      });
    },
    // 获取备份书签列表
    getBookMarkList () {
      let that = this
      window.chrome.bookmarksRecover.getBookmarkBackups((error_code, list)=>{
        // mock相关数据
        if (error_code !== 0 || !this.testStatus(list.status)) {
          that.showMessageTip("加载失败，请重试", "error")
          return
        }
        // 获取今日书签记录
        that.todayBookMark = list.info.today
        that.historyBookMark = {}
        // 归档数据需要按照日期显示
        if (list.info.archivelist) {
          list.info.archivelist.forEach(function (archive) {
            // 首先格式化时间
            let dayStr = that.formatTimeToYmd(archive.add_time)
            if (!that.historyBookMark[dayStr]) {
              that.historyBookMark[dayStr] = []
            }
              that.historyBookMark[dayStr].push(archive)
          })
        }
        that.hasBookMark = that.todayBookMark.length > 0 || Object.keys(that.historyBookMark).length > 0
        that.serverLastAutoDeleteTime = list.info.deltime
      });
    },
    // 获取本地书签数据
    getLocalMark(){
      let that = this
      window.chrome.bookmarks.getTree((tree)=>{
        that.nowMarkNum = 0
        let list = []
        for (let firstMark in tree[0].children) {
          let val = tree[0].children[firstMark]
          // if (firstMark == 2) {
          //   continue
          // }
          let tmp = {
            id: val.id,
            label: val.title,
            type: val.url === undefined ? 'folder' : 'url',
            url: val.url || ""
            // ,expand: firstMark == 0
          }
          if (tmp.type === "url") {
            that.nowMarkNum += 1
          }
          that.nowMarkNum = that.nowMarkNum + that.loopGetLocalMarkChildren(tmp, val.children)
          list.push(tmp)
        }
        that.localMarkInfo = list
        that.showCurrentBook && that.$nextTick(()=>{
          that.$refs.tree && that.$refs.tree.filter(that.currentFilterText)
        })
      });
      // this.localMarkInfo = this.renderMarkDetailToTreeData(list.info)
    },
    filterNode(value, data) {
      if (!value) return true;
      return (data.label||'').toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) !== -1;
    },
    // eslint-disable-next-line no-unused-vars
    renderTreeContent(h, { node, data, store, _self }) {
      // 如果是叶子节点，并且url有值的情况下，那么就获取该url域名下的图标
      if (data.type !== "folder") {
        // 匹配域名信息
        let host = this.getHostFromUrl(data.url)
        if (host) {
          // eslint-disable-next-line no-unused-vars
          data.iconUrl = host + 'favicon.ico'
          data.defaultIcon = defaultIcon
          return (
              <a class="mark_tree_font" href={data.url} target="_blank">
                <img src={data.defaultIcon} class="mark_img" v-real-img={data.iconUrl}></img>
                <span class="mark_tree_font ellipsis-1" href={data.url} target="_blank">{node.label}</span>
              </a>);
        } else {
          return (
              <span>
            <span>{node.label}</span>
          </span>);
        }
      }

      return (
          <span>
            <i class="el-icon-my-close-folder"></i>
            <i class="el-icon-my-open-folder"></i>
            <span class="mark_tree_font">{node.label}</span>
          </span>);
    },
    todayFormat () {
      let da = new Date()
      let month = da.getMonth()+1
      let day = da.getDate()
      return da.getFullYear()+'年'+month+'月'+day+'日'
    },
    // eslint-disable-next-line no-unused-vars
    recoverBookMarkByRow (row) {
      window.chrome.bookmarksRecover.reportId(16690005);//恢复
      this.currentRecoverMarkItem = row
      // 如果用户已经选择过不再提示的情况下，那么就直接做删除处理
      if (this.noTipRecover) {
        this.doRecover(row)
        return
      }
      this.clickRecoverMark = true
    },
    deleteBookMarkByRow (row) {
      window.chrome.bookmarksRecover.reportId(16690006);//删除
      this.currentDeleteMarkItem = row
      if (this.noTipDelete) {
        this.doDelete(row)
        return
      }
      this.clickClearMark = true
    },
    // eslint-disable-next-line no-unused-vars
    todayShowTableMarkItem (row, column, cell, event) {
      this.todayCellIndex = row.id
    },
    // eslint-disable-next-line no-unused-vars
    todayHideTableMarkItem (row, column, cell, event) {
      if (!this.bookMarkClick) {
        this.todayCellIndex = -1
      }
    },
    clickMarkViewItem (row, event) {
      const dialogH = 491 // 弹框的高度
      // 增加上报
      window.chrome.bookmarksRecover.reportId(16690004);//查看
      this.bookMarkClick = true
      this.loadMarkStatus = 'loading'
      // 计算dialog的偏移量
      let Top = event.currentTarget.getClientRects()[0].top
      let Left = event.currentTarget.getClientRects()[0].left
      Top = Math.min(Math.max(0,window.innerHeight - dialogH),Top)
      this.autoDialogPosition.Top = Top
      this.autoDialogPosition.Left = Left
      // 开始获取书签信息
      let that = this
      this.curViewMarkDetail = []
      window.chrome.bookmarksRecover.getBackupDetails(row.id, (error_code, backup)=>{
        if(error_code==0 && backup.constructor === Object && this.testStatus(backup.status)) { // 成功
          that.loadMarkStatus = "success"
          that.curViewMarkDetail = that.renderMarkDetailToTreeData(backup.info)
        }else if(error_code==1) { // 未登录
          that.hasLogin = false
          return
        } else { // 说明服务数据有问题
          this.loadMarkStatus = "fail"
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    historyShowTableMarkItem (row, column, cell, event) {
      this.historyCellIndex = row.id
    },
    // eslint-disable-next-line no-unused-vars
    historyHideTableMarkItem (row, column, cell, event) {
      if (!this.bookMarkClick) {
        this.historyCellIndex = -1
      }
    },
    // eslint-disable-next-line no-unused-vars
    renderTableHeader (obj) {
      if (obj.columnIndex === 0) {
        return "'width': '500px!important'; 'overflow': 'visible'"
      }
      return {'width': '0px', 'overflow': 'visible'}
    },
    // eslint-disable-next-line no-unused-vars
    renderTableCell (obj) {
      if (obj.columnIndex === 0) {
        return {'padding-left': '40px', 'height': '60px'}
      }
      if (obj.columnIndex === 1) {
        return {'font-size': '14px', 'font-family': 'Microsoft YaHei', 'font-weight': 'Bold'}
      }
      if (obj.columnIndex === 3) {
        return {'padding-left': '20px'}
      }
    },
    cancelRecoverTip () {
      this.noTipRecover = false
      this.clickRecoverMark = false
    },
    cancelDeleteTip () {
      this.noTipDelete = false
      this.clickClearMark = false
    },
    // eslint-disable-next-line no-unused-vars
    doRecover(markItem) {
      // 记住用户的不再弹窗的提示,写入到本地缓存
      if (this.noTipRecover) {
        localStorage.setItem(Common.NoTipRecoverKey, "true")
      } else {
        localStorage.removeItem(Common.NoTipRecoverKey)
      }
      // 检查网络状态
      this.getSysOnLine()
       if(!this.isOnLine){
         this.showMessageTip("恢复失败，请检查网络后重试！", "error")
          this.clickRecoverMark = false
         return
       }
      // 显示恢复loading
      let waitTipMsg = this.showMessageTip('正在恢复，请稍等', 'info')
      let that = this
      // eslint-disable-next-line no-unused-vars
      window.chrome.bookmarksRecover.recoverBookmarks(markItem.id, (error_code, res)=>{

        waitTipMsg.close()
        this.clickRecoverMark = false
        if (error_code === 0 && this.testStatus(res.status)) { // 说明恢复成功了
          this.showMessageTip('恢复成功', 'success')
        } else if (error_code == 1) {
          that.hasLogin = false
        } else {
          this.showMessageTip('恢复失败，请检查网络后重试', 'error')
        }
      });
      this.clickRecoverMark = false
    },
    // eslint-disable-next-line no-unused-vars
    doDelete(markItem) {
      // 记住用户的不再弹窗的提示,写入到本地缓存
      if (this.noTipDelete) {
        localStorage.setItem(Common.NoTipDeleteKey, "true")
      } else {
        localStorage.removeItem(Common.NoTipDeleteKey)
      }
       this.getSysOnLine()
       if(!this.isOnLine){
         this.showMessageTip("删除失败，请检查网络后重试！", "error")
          this.clickClearMark = false
         return
       }
      let that = this
      const waitMsg = this.showMessageTip("正在删除，请稍等", "info")
      // eslint-disable-next-line no-unused-vars
      window.chrome.bookmarksRecover.removeBackup(markItem.id, (error_code, res)=>{
        that.clickClearMark = false
        waitMsg.close()
        if(error_code==0 && this.testStatus(res.status)) {
          that.showMessageTip("删除成功", "success")
          // 删除成功的情况下自动刷新列表数据
          that.getBookMarkList()
        } else {
          that.showMessageTip("删除失败，请检查网络后重试", "error")
        }
      });
    },
    clickCurrentMark (event) {
      this.curMarkDialogPos.Top = event.currentTarget.getClientRects()[0].top
      this.curMarkDialogPos.Left = event.currentTarget.getClientRects()[0].left
      this.showCurrentBook = true
      // 增加上报
      window.chrome.bookmarksRecover.reportId(16690004);//查看
    },
    initData(){
      // 是否弹出恢复弹窗
      if (localStorage.getItem(Common.NoTipRecoverKey) === "true") {
        this.noTipRecover = true
      }
      // 是否弹出清除弹窗
      if (localStorage.getItem(Common.NoTipDeleteKey) === "true") {
        this.noTipDelete = true
      }
      // 本地删除的时间
      if (localStorage.getItem(Common.LocalAutoDeleteTimeKey)) {
        this.localAutoDeleteTime = localStorage.getItem(Common.LocalAutoDeleteTimeKey)
      }
    },
    formatTimeToYmd(dateTime){
      let date = new Date(Date.parse(dateTime))
      return date.getFullYear()+'年'+(date.getMonth()+1)+'月'+date.getDate()+'日'
    },
    formatTimeToHms(dateTime){
      let date = new Date(Date.parse(dateTime))
      let hour = date.getHours() < 10 ? '0'+date.getHours() : date.getHours()
      let min = date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes()
      return hour+':'+min
    },
    // 渲染书签成tree的数据结构
    renderMarkDetailToTreeData (info) {
      let list = []
      let isFirstNode = true
      for (let markKey in info) {
        let mark = info[markKey]
        // 处理当前书签
        let bookmark = mark.roots.bookmark_bar
        let tmp = {
          id: bookmark.id,
          label: bookmark.name,
          type: bookmark.type,
          children: [],
          url: bookmark.url || "",
          expand: isFirstNode
        }
        isFirstNode = false
        // 遍历所有的子类型
        this.loopGetMarkChildren(tmp, bookmark.children)
        list.push(tmp)
        // 处理其它书签
        let otherBookmark = mark.roots.other || {}
        let otherTmp = {
          id: otherBookmark.id,
          label: otherBookmark.name,
          type: otherBookmark.type,
          children: [],
          url: otherBookmark.url || ""
        }
        // 遍历所有的子类型
        this.loopGetMarkChildren(otherTmp, otherBookmark.children)
        list.push(otherTmp)
        // 处理手机书签
        let syncedBookmark = mark.roots.synced
        if(syncedBookmark && syncedBookmark.children && syncedBookmark.children.length){
          let syncedTmp = {
            id: syncedBookmark.id,
            label: '手机书签',// syncedBookmark.name,
            type: syncedBookmark.type,
            children: [],
            url: syncedBookmark.url || ""
          }
          // 遍历所有的子类型
          this.loopGetMarkChildren(syncedTmp, syncedBookmark.children)
          list.push(syncedTmp)
        }
      }
      return list
    },
    loopGetMarkChildren(parent, children) {
      if (!children || children.length <= 0) {
        parent.children = []
        return
      }
      if (!parent.children) {
        parent.children = []
      }
      // 遍历所有的子节点
      for (let index in children) {
        let val = children[index]
        let tmp = {
          id: val.id,
          label: val.name,
          type: val.type,
          url: val.url || ""
        }
        this.loopGetMarkChildren(tmp, val.children)
        parent.children.push(tmp)
      }
    },
    // eslint-disable-next-line no-unused-vars
    loopGetLocalMarkChildren(parent, children) {
      let calNum = 0
      if (!children || children.length <= 0) {
        parent.children = []
        return calNum
      }
      if (!parent.children) {
        parent.children = []
      }
      for (let index in children) {
        let val = children[index]
        let tmp = {
          id: val.id,
          label: val.title,
          type: val.url === undefined ? 'folder' : 'url',
          url: val.url || ""
        }
        if (tmp.type === "url") {
          calNum += 1
        }
        calNum += this.loopGetLocalMarkChildren(tmp, val.children || [])
        parent.children.push(tmp)
      }
      return calNum
    },
    // 匹配host方法
    getHostFromUrl (url) {
      let hostReg = /^http(s)?:\/\/(.*?)\//
      let match = hostReg.exec(url)
      if (match) {
        return match[0]
      }

      return ""
    },
    // 关闭删除备份提示
    closeShowBackDeleteTip () {
      localStorage.setItem(Common.LocalAutoDeleteTimeKey, this.serverLastAutoDeleteTime)
      this.showBackDeleteTip = false
    },
    // 弹窗提示语
    showMessageTip (message, type, icon) {
      const m = this.$message({
        message: message,
        center: true,
        type: type,
        iconClass: icon,
        customClass: 'my_message_tip',
        duration: 2000
      });

      return m
    },
    // 是否是浏览器自身
    isSelfBrowser() {
      if (window.tsbrowser) {
        return true
      }

      return false
    },
    // 是否支持书签版本
    isSupportVersion () {
      if (window.chrome.bookmarksRecover) {
        return true
      }

      return false
    },
    // 获取当前设备名称
    getDeviceName () {
      let that = this
      if(window.chrome.bookmarksRecover){
        window.chrome.bookmarksRecover.getDeviceName((device)=>{
          that.deviceName = device
        });
      }
    },
    // 检查系统网络状态
    getSysOnLine(){
      this.isOnLine = window.navigator.onLine
    },
    testStatus(status){
      return typeof  status === 'undefined' || status == 1 
    }
  },
  created() {
    this.isShowNotSelfBrowserTip = !this.isSelfBrowser()
    this.isShowLowVersionTip = this.isSelfBrowser() && !this.isSupportVersion()
    this.getDeviceName()
    this.getSysOnLine()
    if (!this.isSelfBrowser()) {
      return
    }
    this.initData()
    this.checkLoginStatus()
    // 加载本地列表数据
    this.getLocalMark()
    // 监听登录注销事件
    let that = this
    window.chrome.bookmarksRecover.loginStatus.addListener((status)=>{
      if(status === 0) {
        that.hasLogin = false
        this.getDeviceName()
      } else if (status === 1) {
        that.hasLogin = true
        // 登录成功
        this.getDeviceName()
        that.getBookMarkList()
      }
    });
    // 监听书签操作动作
    window.chrome.bookmarksRecover.bookmarksSync.addListener(()=>{
      this.hasLogin && this.getBookMarkList()
    });
    // 监听书签变化
    window.chrome.bookmarksRecover.bookmarksChange.addListener(()=>{
      this.getLocalMark()
    });
  }
}
</script>

<style lang="css">
  .table-row {
    position: relative;
    height: 60px;
  }
  .book_time {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .book_num {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 19px;
    color: #333333;
    opacity: 1;
  }
  .my_dialog .el-dialog__body {
    padding:20px 0px!important
  }
   .my_dialog .need-padding {
     padding: 0 20px;
  }
  .my_dialog .el-tree-node:hover>.el-tree-node__content {
    background-color:#EBEBEB;
  }
  .my_dialog .el-tree-node:focus>.el-tree-node__content {
    background-color:#DDD;
  }
  
  .my_dialog .el-tree-node > .el-tree-node__content .el-icon-my-close-folder,.my_dialog .el-tree-node.is-expanded > .el-tree-node__content .el-icon-my-open-folder{
    display: unset;
  }
  .my_dialog .el-tree-node.is-expanded > .el-tree-node__content .el-icon-my-close-folder,.my_dialog .el-tree-node > .el-tree-node__content .el-icon-my-open-folder{
    display: none;
  }

  .mark_content .table_header {
    font-weight: normal;
    font-size: 16px;
  }
  .mark_content .el-table th tr::before {
    border: none;
  }
  .mark_content .el-table th>.cell {
    padding: 6px 10px;
  }
  .mark_content .el-table td, .el-table th.is-leaf {
    border-bottom: none;
  }
 .mark_content .el-table tbody tr:hover>td { 
    background-color:#EBEBEB!important
  }
  .v-modal {
    background: transparent !important;
  }
</style>